.App {
  text-align: center;
  background-color: #fff;
  font-family: Avenir, Helvetica, sans-serif;
  width: 100vw;
}

.App-header {
  position: relative;
  background-color: #981b1e;
  min-height: 10vh;
  height: 148px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-logo {
  height: 96px;
}

h3 {
  	color: #981b1e;
  	text-align: center;
	text-transform: uppercase;
	font-weight: medium;
	font-size: 1.3em;
	margin-top: 1.5em;
}
a {
  	color: #981b1e;
}
a:hover {
  	color: #ba1b1e;
}
.red {
  	background-color: #981b1e;
}
.yellow {
	background-color: #ffe17f;
}
.upperleft-corner {
	position: absolute;
	left: 12px;
	top: 12px;
	width: 8px;
	height: 8px;
}
.lowerleft-corner {
	position: absolute;
	left: 12px;
	bottom: 12px;
	width: 8px;
	height: 8px;
}
.upperright-corner {
	position: absolute;
	right: 12px;
	top: 12px;
	width: 8px;
	height: 8px;
}
.lowerright-corner {
	position: absolute;
	right: 12px;
	bottom: 12px;
	width: 8px;
	height: 8px;
}


.company-info {
	position: relative;
	padding: 48px 10% 48px;
    min-height: 10vh;
}
.company-info .bio, .company-info .about {
	display: inline-block;
    vertical-align: top;
	text-align: left;
	width: 30vw;
	margin: 8px 24px;
	font-size: 1.2em;
	line-height: 1.6em;
}
.project-container, .client-container {
	position: relative;
	padding: 48px 10% 28px;
	min-height: 200px;
}

.client-container ul {
	list-style: none;
	padding-inline-start: 0;
}
.client-container li {
	font-size: 0.9em;
	padding: 4px;
}

.company-portfolio {
	position: relative;
	padding: 48px;
    min-height: 10vh;
	border-top: 4px solid #981b1e;
	border-bottom: 4px solid #981b1e;
	text-align: left;
}
.client-info {
	text-align: right;
}
.client-info a {
	text-decoration: none;
}
.company-portfolio .scroller {
    display: grid;
    overflow-x: scroll;
    list-style: none;
    padding-left: 0;
    grid-gap: 2vw;
  	grid-template-columns:
    	repeat(10, calc(25%));
  	grid-template-rows: minmax(150px, 1fr);
}
.project {
	padding: 4px;
	overflow: hidden;
}
.project h3 {
	font-weight: normal;
	font-size: 1.3em;
	text-align: left;
	text-transform: none;
}
.project a {
	text-decoration: none;
}
.project .videocontainer, .project-container .videocontainer {
  width: 100%;
  padding-top: 60%; /* 1:1 Aspect Ratio */
  position: relative; /* If you want text inside of it */
}
.videocontainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.project .thumbnail {
	width: 95%;
	height: auto;
	object-fit: scale-down;
	object-position: 0 0;
	text-align: left;
}
.project .info {
	font-size: 0.8em;
}
.project-details {
	max-width: 50vw;
	text-align: left;
	padding: 20px 20%;
}
.project-container h3 {
	font-weight: medium;
	font-size: 1.6em;
}
.project-container .thumbnail {
	width: 400px;
	height: auto;
	object-fit: scale-down;
}
.previous-project a {
	text-decoration: none;
	font-size: 2.0em;
	position: absolute;
	bottom: 12px;
	left: 12px;
}
.next-project a {
	text-decoration: none;
	position: absolute;
	bottom: 12px;
	right: 12px;
	font-size: 2.0em;
}
.home-link {
	margin-top: 20px;
}
.home-link img {
	width: 18px;
	height: auto;
}
.stronger {
	font-weight: 500;
	color: #330000;
}

.company-portfolio .yellow-strip
{
	background-image: url("assets/yellow_square_bg.png");
	background-repeat: repeat-x;
	height: 32px;
}

/* Hide scrollbar */
.hide-scroll {
  overflow-y: hidden;
  margin-bottom: calc(-.1 * var(--gutter));
}

.company-bios {
	position: relative;
	padding: 48px 20% 48px;
    min-height: 10vh;
}
.footer {
	position: relative;
	padding: 48px;
    min-height: 10vh;
    height: 300px;
}
.footer .logo {
	height: 280px;
}

	
@media (max-width: 600px) 
{
.App-logo {
	height: 72px;
}
.company-info {
	padding: 24px 10% 48px;
	font-size: 12px;
}
.company-info .about,
.company-info .bio {
	width: 70vw;
}
.company-portfolio {
	padding: 24px;
}
.company-portfolio .scroller {
  	grid-template-columns:
    	repeat(12, calc(40%));
}
.project h3 {
	font-size: 1.0em;
}
.project .info {
	font-size: 0.7em;
}
.project-details {
	padding: 8px;
	max-width: 80vw;
}
.project-container {
	padding: 16px;
}
.project-container .thumbnail {
	width: 80vw;
}
.footer {
	position: relative;
	padding: 24px;
	min-height: 10vh;
	height: 240px;
}	
.footer .logo {
	height: 180px;
}
}
